export interface ViolationReportForm {
	violationCategory: ViolationCategory;
	reportType: ReportType;
	anonymousType: AnonymousType;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	description: string;
	attachments?: File[];
	captchaToken: string;
}

export enum ReportType {
	FIRST_REPORT = 'firstReport',
	NEXT_REPORT = 'nextReport'
}

export enum ViolationCategory {
	FRAUD = 'FRAUD',
	CONFLICT_OF_INTEREST = 'CONFLICT_OF_INTEREST',
	ENVIRONMENTAL_PROTECTION = 'ENVIRONMENTAL_PROTECTION',
	HUMAN_RIGHTS = 'HUMAN_RIGHTS',
	DATA_PROTECTION = 'DATA_PROTECTION',
	CARTEL_LAW_AND_MONEY_LAUNDERING = 'CARTEL_LAW_AND_MONEY_LAUNDERING',
	BREACH_OF_DUE_DILIGENCE = 'BREACH_OF_DUE_DILIGENCE',
	OTHER = 'OTHER'
}

export enum AnonymousType {
	ANONYMOUS = 'ANONYMOUS',
	NOT_ANONYMOUS = 'NOT_ANONYMOUS'
}
